import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AgmMap, MapsAPILoader, GoogleMapsAPIWrapper } from '@agm/core';
import { MapLocation } from './location-map.model';
import { RadiusOption, radiusOptions } from '../../dashboard/servicesComponets/disaster/radiusOptions';

declare var google: any;

@Component({
  selector: 'location-map',
  providers: [],
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.css'],
})
export class LocationMapComponent implements OnChanges, AfterViewInit {
  private countryInternal: string;

  private geocoder: any;

  @Input()
  set country(country: string) {
    if (country) {
      this.countryInternal = country;
      this.loadCountry();
      if (this.editable) {
        this.clearLocations();
      }
    }
  }

  @Input() showAll: boolean = false;

  get country() {
    return this.countryInternal;
  }

  @Input() source: MapLocation | MapLocation[] | null;

  @Input() multi: boolean = false;

  @Input() editable: boolean = true;

  @Input() showInfoWindow: boolean = true;

  @Output() locationAdded: EventEmitter<MapLocation> = new EventEmitter<MapLocation>();

  @Output() radiusChanged: EventEmitter<MapLocation> = new EventEmitter<MapLocation>();

  @Output() locationRemoved: EventEmitter<MapLocation> = new EventEmitter<MapLocation>();

  @ViewChild(AgmMap, { static: false }) map: AgmMap;

  mapsApiLoader: MapsAPILoader;

  // Default coordinates, in case country is not selected.
  centerLatitude: number = -34.397;
  centerLongitude: number = 150.644;

  radii: RadiusOption[] = radiusOptions;

  constructor(private mapsApi: MapsAPILoader, public gMaps: GoogleMapsAPIWrapper) {
    this.mapsApiLoader = mapsApi;
    this.loadApi();
    this.source = this.source ? (this.source instanceof Array ? this.source : [this.source]) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      this.source = changes.source.currentValue;
    }
  }
  
  ngAfterViewInit() {
    this.map.triggerResize(true);
  }

  loadApi() {
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  loadCountry() {
    const location = this.country;
    if (!this.geocoder) {
      this.mapsApiLoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
        const location = this.country;
        this.getCountryDetails(location);
      });
    } else {
      this.getCountryDetails(location);
    }
  }

  getCountryDetails(location: string) {
    this.geocoder.geocode({ address: location }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        this.centerLatitude = results[0].geometry.location.lat();
        this.centerLongitude = results[0].geometry.location.lng();
        this.map.triggerResize(true);
      } else {
        alert('Could not find location: ' + location);
      }
    });
  }
  onMarkerClicked(marker) {
    marker.visible = !marker.visible;
  }
  mapClicked($event: any): void {
    if (!this.editable) {
      return;
    }

    this.centerLatitude = $event.coords.lat;
    this.centerLongitude = $event.coords.lng;

    const location = {
      geoLatitude: $event.coords.lat,
      geoLongitude: $event.coords.lng,
      radius: this.radii[0].value,
    };

    if (this.source === null) {
      this.source = [];
    }
    if (!(this.source instanceof Array)) {
      this.source = [this.source];
    }
    (<MapLocation[]>this.source).push(location);

    location.radius = this.radii[0].value;
    this.locationAdded.emit(location);
  }

  getDisplayValue(rad: number): string {
    const displayValue = this.radii.find((radius) => radius.value === rad);
    return displayValue.display;
  }

  onRadiusChanged(radius: number, geoLatitude: number, geoLongitude: number): void {
    const location = {
      geoLatitude,
      geoLongitude,
      radius,
    };
    if (this.multi) {
      const sourceLocation = (<MapLocation[]>this.source).filter(
        (location) => location.geoLatitude === geoLatitude && location.geoLongitude === geoLongitude
      )[0];
      sourceLocation.radius = radius;
    } else {
      (<MapLocation>this.source).radius = radius;
    }
    this.radiusChanged.emit(location);
  }

  onMarkerDelete(location: MapLocation): void {
    if (this.multi) {
      const index = (<MapLocation[]>this.source).indexOf(location);
      (<MapLocation[]>this.source).splice(index, 1);
    } else {
      this.source = null;
    }
    this.locationRemoved.emit(location);
  }

  clearLocations() {
    if (this.source) {
      if (this.multi) {
        this.source = [];
      } else {
        this.locationRemoved.emit(<MapLocation>this.source);
      }
    }
  }

  resize() {
    this.map.triggerResize();
  }
}
