import { finalize } from 'rxjs/operators';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from '../../../login/login.service';
import { LogoUploadService } from './logo-upload.service';
import { handleErrors } from '../../../common/error';
import { AppSettings } from '../../../common/config';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MaterializeAction } from 'angular2-materialize';
import { isNullOrUndefined } from 'util';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'logo-upload',
  providers: [],
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.css'],
})
export class LogoUploadComponent {
  modalActions = new EventEmitter<string | MaterializeAction>();
  @Output() logoChanged = new EventEmitter<string>();

  error: string;
  isSubmitting: boolean = false;
  showDeleteBtn: boolean = false;
  AppSettings = AppSettings;
  imageChangedEvent: any = '';
  croppedImage: string = '';
  logoUri: string;

  constructor(
    public authService: AuthenticationService,
    private uploadService: LogoUploadService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  @Input('logoUri') set setlogoUri(value: string) {
    if (isNullOrUndefined(value)) {
      this.logoUri = AppSettings.DEFAULT_LOGO_PATH;
    } else {
      this.logoUri = value;
      this.showDeleteBtn = true;
    }
  }

  @Input('organizationId') orgId: number;

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  openModal(): boolean {
    if (this.authService.isSuper() || this.authService.isOrgGeneral()) {
      this.modalActions.emit({ action: 'modal', params: ['open'] });
    }

    return false;
  }

  onDeleteLogo(): void {
    if (confirm(this.translateService.instant('LogoUploadComponent-DELETE_LOGO_CONFIRMATION'))) {
      this.isSubmitting = true;

      this.uploadService.deleteLogo(this.orgId).subscribe(
        () => {
          this.notificationsService.success(
            this.translateService.instant('LogoUploadComponent-LOGO-UPLOAD-TITLE'),
            this.translateService.instant('LogoUploadComponent-SUCCESS_DELETE_MESSAGE')
          );

          this.modalActions.emit({ action: 'modal', params: ['close'] });
          this.logoChanged.emit(null);

          this.logoUri = AppSettings.DEFAULT_LOGO_PATH;

          this.isSubmitting = false;
        },
        (error) => {
          handleErrors(
            error,
            this.translateService,
            this.notificationsService,
            'LogoUploadComponent-LOGO-UPLOAD-TITLE'
          );

          this.isSubmitting = false;
        }
      );
    }
  }

  b64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = [];
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onFileSelected(event: any): void {
    this.imageChangedEvent = event;

    const targetFile = event.target.files[0];

    if (!(targetFile instanceof File)) {
      return;
    }

    this.error = null;

    if (targetFile.size > AppSettings.MAXIMUM_IMAGE_UPLOAD_SIZE) {
      this.error = this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR');
      return;
    }
    if (AppSettings.DEFAULT_ALLOWED_IMAGE_TYPES.indexOf(targetFile.type) === -1) {
      this.error = this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE');
      return;
    }
  }

  onUploadCancel(): void {
    this.croppedImage = '';
    this.imageChangedEvent = '';
  }

  onUploadFile(): void {
    this.isSubmitting = true;

    const imageBlob = this.b64toBlob(this.croppedImage.split(',')[1], 'image/png');

    this.uploadService
      .uploadLogo(new File([imageBlob], 'my_image.png'), this.orgId)
      .pipe(
        finalize(() => {
          this.croppedImage = '';
          this.imageChangedEvent = '';
        })
      )
      .subscribe(
        (response) => {
          this.notificationsService.success(
            this.translateService.instant('LogoUploadComponent-LOGO-UPLOAD-TITLE'),
            this.translateService.instant('LogoUploadComponent-SUCCESS_MESSAGE')
          );

          this.isSubmitting = false;
          this.logoUri = `${response.uri}`;
          this.logoChanged.emit(this.logoUri);
          this.modalActions.emit({ action: 'modal', params: ['close'] });
        },
        (error) => {
          this.isSubmitting = false;

          handleErrors(
            error,
            this.translateService,
            this.notificationsService,
            'LogoUploadComponent-LOGO-UPLOAD-TITLE'
          );
        }
      );
  }
}
